import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import B2BCourseTemplateModel from '@uz/unitz-models/B2BCourseTemplateModel';
import _ from 'lodash';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),

            paginationModel: hook((ctx) => {
              const account_id = ctx.apply('accountModel.getAccountId');
              const pagingModel = React.useMemo(() => {
                const condition = `where: {
                  account_id: {_eq: "${account_id}"}
                }`;
                const dataQuery = `
                  b2b_course_template(${condition}, order_by: {created_at: desc_nulls_last}){
                    id
                    user_id
                    name
                    account_id
                    acl_subject
                  }
                `;
                const pagingModel = PaginationModel.fromConfig({
                  Model: B2BCourseTemplateModel,
                  dataQuery,
                  aggQuery: `
                    b2b_course_template_aggregate(${condition}) {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 3,
                });
                return pagingModel;
              }, []);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const pagination = {
                items: pagingModel.useState('items'),
                page: pagingModel.useState('page'),
                total: pagingModel.useState('total'),
                isLoading: pagingModel.useState('isLoading'),
                pageSize: pagingModel.useState('pageSize'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
                onRefresh: (...args) => pagingModel.onRefresh(...args),
                onPreviousPage: (...args) => pagingModel.onPreviousPageHandler(...args),
                onNextPage: (...args) => pagingModel.onNextPageHandler(...args),
                onLoadPage: (...args) => pagingModel.onLoadPageHandler(...args),
              };

              return pagination;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
