import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Button } from '@uz/unitz-components-web/Button';
import { Link } from '@uz/unitz-components-web/Link';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import CourseUseTemplateItemX from '@uz/unitz-tool-components/CourseUseTemplateItemX';
import Paginator from '@uz/unitz-components-web/Paginator';
import _ from 'lodash';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4 h-full">
          <Row align="middle" justify="space-between">
            <Col>
              <div className="text-main font-semibold text-base">
                {ctx.apply('i18n.t', 'ZoomToolCourse.UseTemplate.choose_template_title')}
              </div>
            </Col>
            <Col>
              <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplateCreate')}>
                <Button type="primary" size="medium">
                  <div className="flex items-center">
                    <div className="text-white500 font-semibold">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Action.create_course_from_scratch')}
                    </div>
                    <div>
                      {gstyles.icons({
                        name: 'plus',
                        size: 14,
                        fill: gstyles.colors.white500,
                        className: 'mx-2',
                      })}
                    </div>
                  </div>
                </Button>
              </Link>
            </Col>
          </Row>
          <div className="template-list">
            {renderPropsComposer(
              {
                matcher: (props) => !_.get(props, 'items.length') && !_.get(props, 'isLoading'),
                render: () => (
                  <Row justify="center" className="py-16">
                    <Col span={12}>
                      <div className="flex flex-col items-center justify-center">
                        <div className="mb-4">
                          {gstyles.icons({
                            name: 'courseEmpty',
                            size: 152,
                          })}
                        </div>
                        <div className="mb-6 text-center text-2xl font-semibold text-sub">
                          {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.empty_text1')}
                        </div>
                        <div className="text-2xl text-center font-semibold text-sub">
                          {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.empty_text2')}
                        </div>
                      </div>
                    </Col>
                  </Row>
                ),
              },
              {
                matcher: (props) => !_.get(props, 'items.length') && !!_.get(props, 'isLoading'),
                render: () => (
                  <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 select-none bg-white200">
                    <LoadingScreen />
                  </div>
                ),
              },
              {
                matcher: (props) => !!_.get(props, 'items.length') && !_.get(props, 'isLoading'),
                render: () => (
                  <>
                    <Row gutter={[0, 16]} justify="center" className="py-4">
                      {_.map(ctx.get('paginationModel.items'), (item, index) => (
                        <React.Fragment key={index}>
                          <DIV>
                            {ctx.debug(() => {
                              ctx.set('@item', item);
                            })}
                            <CourseUseTemplateItemX />
                          </DIV>
                        </React.Fragment>
                      ))}
                    </Row>
                    <Paginator />
                  </>
                ),
              },
              () => null
            )(ctx.get('paginationModel'))}
          </div>

          <div className="my-3 p-6 rounded-lg shadow-md bg-white500">
            <Row justify="end">
              <Col span={4}>
                <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourses')}>
                  <Button
                    style={{ border: `1px solid ${gstyles.colors.border}` }}
                    type="outline"
                    name="ink"
                    size="medium"
                    block
                  >
                    {ctx.apply('i18n.t', 'ZoomToolCourse.Action.back')}
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
