import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import Link from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';

const View8 = () => {
  return (
    <DIV className="component">
      <div className="p-8 w-full bg-white500 rounded-lg">
        <Row align="middle" justify="space-between">
          <Col>
            <div className="uppercase text-base text-main font-semibold">{ctx.get('courseTemplateItemInfo.name')}</div>
          </Col>
          <Col>
            <Row gutter={[16, 16]}>
              <Col>
                <Link
                  to={ctx.apply('routeStore.toUrl', 'toolAccountCourseUseTemplate', ctx.get('courseTemplateItemInfo'))}
                >
                  <Button size="medium" type="primary">
                    {ctx.apply('i18n.t', 'ZoomToolCourse.Action.use_this_template')}
                  </Button>
                </Link>
              </Col>
              <Col>
                <Link
                  to={ctx.apply(
                    'routeStore.toUrl',
                    'toolAccountCourseTemplateDetail',
                    ctx.get('courseTemplateItemInfo')
                  )}
                >
                  <Button style={{ border: `1px solid ${gstyles.colors.placeHolder}` }} size="medium" type="outline">
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.view')}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </DIV>
  );
};

export default displayName(View8);
